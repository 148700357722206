<template>
  <div class="main-content">
    <h3 class="page-heading">Adhoc Payments</h3>
    <table>
      <thead>
        <tr>
          <th>Id</th>
          <th>Amount</th>
          <th>Date</th>
          <th>Description</th>
          <th>From Id</th>
          <th>From Code</th>
          <th>From Name</th>
          <th>To Id</th>
          <th>To Type</th>
          <th>To Name</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="adhoc in getAdhocs" :key="adhoc.id">
          <td>{{ adhoc.id }}</td>
          <td>{{ this.formatMoney(adhoc.amount) }}</td>
          <td>{{ this.formatDate(adhoc.date) }}</td>
          <td>{{ adhoc.description }}</td>
          <td>{{ adhoc.operatorFrom.id }}</td>
          <td>{{ adhoc.operatorFrom.type }}</td>
          <td>{{ adhoc.operatorFrom.name }}</td>
          <td>{{ adhoc.operatorTo.id }}</td>
          <td>{{ adhoc.operatorTo.type }}</td>
          <td>{{ adhoc.operatorTo.name }}</td>
          <td>{{ adhoc.status }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import store from "@/store";
  import { useToast } from "vue-toastification";

  export default {
    data() {
      return {
        adhocs: [],
      };
    },

    computed: {
      getAdhocs() {
        const adhocs = this.adhocs;

        return adhocs.sort((a, b) => {
          if (a.date < b.date) {
            return 1;
          } else if (a.date > b.date) {
            return -1;
          }

          if (a.id > b.id) {
            return 1;
          } else if (a.id < b.id) {
            return -1;
          }

          return 0;
        });
      },
    },

    mounted() {
      store.state.apiPrivate.client.endpoints.adhoc
        .query({
          dateFrom: "2023-01-01",
          dateTo: "2024-01-01",
        })
        .then((response) => {
          if (response.status == 200) {
            return response.data.data;
          }

          if (response.status == 404) {
            return [];
          }

          return Promise.reject(response);
        })
        .then((data) => {
          this.adhocs = data;
        })
        .catch((ex) => {
          window.log.error("Failed to download adhoc invoices.", ex);
          useToast().error("Failed to download adhoc invoices.");

          this.adhocs = [];
        });
    },
  };
</script>
